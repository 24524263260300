<template>
  <div class="edu-banner-area banner03">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="edu-banner-content">
            <h1>
              Classical <span class="shape01">Portfolio</span> For The Future
            </h1>
            <p>
             Unleash your creativity and let your work shine with our intuitive portfolio builder.
            </p>
            <div class="edu-banner-btns">
              <a class="default-btn" href="#">Get Started Now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="edu-banner-image3">
            <img src="../../assets/img/banner/man3.png" alt="banner-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>