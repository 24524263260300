<template>
  <div class="edu-faq-area sectionBg15 ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="edu-faq-content">
            <p class="sub-title">Frequently Asked Question</p>
            <h2>General <span class="shape02">Questions</span></h2>
          </div>
          <div class="accordion edu-faqs-list" id="accordionFlushExample">
            <div class="accordion-item faq-item">
              <h2 class="faq-header" id="flush-headingOne">
                <button
                  class="accordion-button faq-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  How do I get started creating my portfolio?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse faq-collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="faq-item-body">
                  <p>
                    To get started, simply sign up for an account on our website. Once logged in, you can choose from a variety of professionally designed templates. Customize your portfolio by adding your work samples, projects, and personal information using our intuitive drag-and-drop editor.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item faq-item">
              <h2 class="faq-header" id="flush-headingTow">
                <button
                  class="accordion-button faq-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTow"
                  aria-expanded="false"
                  aria-controls="flush-collapseTow"
                >
                  What customization options are available for portfolios?
                </button>
              </h2>
              <div
                id="flush-collapseTow"
                class="accordion-collapse collapse faq-collapse"
                aria-labelledby="flush-headingTow"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="faq-item-body">
                  <p>
                    We offer extensive customization options to ensure your portfolio reflects your unique style. You can personalize layouts, colors, fonts, and backgrounds. Additionally, you can add multimedia elements such as images, videos, and links to external content to enhance your portfolio.

                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item faq-item">
              <h2 class="faq-header" id="flush-headingThree">
                <button
                  class="accordion-button faq-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Is my data and portfolio content secure?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse faq-collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="faq-item-body">
                  <p>
                    Yes, protecting your data and portfolio content is our top priority. We use industry-standard encryption protocols to safeguard all information stored on our servers. Rest assured that your personal and professional data is secure with us.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item faq-item">
              <h2 class="faq-header" id="flush-headingFour">
                <button
                  class="accordion-button faq-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  How can I share my portfolio with others once it's created?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                class="accordion-collapse collapse faq-collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="faq-item-body">
                  <p>
                    Sharing your portfolio is simple. You can generate a unique URL for your portfolio that you can share via email, social media, or embed it on your personal website. Additionally, our platform provides social sharing buttons for seamless integration with various social media platforms, allowing you to reach a wider audience effortlessly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="edu-about-image">
            <img src="../../assets/img/all-img/faq.png" alt="banner-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
};
</script>