<template>
  <div class="calendly-inline-widget" :data-url="calendlyUrl" :style="calendlyStyles"></div>

 <div class="edu-contact-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-12">
          <div class="edu-content">
            <div class="edu-contact-title">
              <p class="sub-title">Contact Us</p>
              <h2>Get In Touch <span class="shape02">Today</span></h2>

            </div>
            <div class="edu-contact-info-box">
              <div class="icon">
                <img src="../../assets/img/svg/mail.svg" alt="icon" />
              </div>
              <h3>Email-Us :</h3>
              <p>
                <a href="mailto:hello@guto.com">contact@showyourskills.fi</a>
              </p>
            </div>

            <div class="edu-contact-info-box">
              <div class="icon">
                <img src="../../assets/img/svg/call.svg" alt="icon" />
              </div>
              <h3>Call Us:</h3>
              <p>
                <a href="tel:+358403654632">
                  +358403654632
                </a>
              </p>
            </div>

            <div class="edu-contact-info-box">
              <div class="icon">
                <img src="../../assets/img/svg/map.svg" alt="icon" />
              </div>
              <h3>Office :</h3>
              <p>Vanha-Juvankatu 2 E 34, 33710 Tampere</p>
            </div>
          </div>
        </div>
     </div>
   </div>
 </div>

</template>

<script>
export default {
  name: 'CalendlyWidget',
  data() {
    return {
      calendlyUrl: 'https://calendly.com/phongtran-showyourskills',
      calendlyStyles: {
        height: '650px'
        
      }
    };
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  }
};
</script>

<style scoped>
.calendly-inline-widget {
  width: 100%;
  max-width: 900px;
  margin-left: 1000px;
  margin-bottom: -600px;
}
</style>
