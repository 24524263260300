import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavbar = _resolveComponent("MainNavbar")!
  const _component_MainBanner = _resolveComponent("MainBanner")!
  const _component_BrandsContent = _resolveComponent("BrandsContent")!
  const _component_AboutUs = _resolveComponent("AboutUs")!
  const _component_CoreFeatures = _resolveComponent("CoreFeatures")!
  const _component_PopularTopics = _resolveComponent("PopularTopics")!
  const _component_PopularCourses = _resolveComponent("PopularCourses")!
  const _component_TeamMember = _resolveComponent("TeamMember")!
  const _component_SubscribeNewsletter = _resolveComponent("SubscribeNewsletter")!
  const _component_OurTestimonial = _resolveComponent("OurTestimonial")!
  const _component_UpcomingEvents = _resolveComponent("UpcomingEvents")!
  const _component_BuildYourCareer = _resolveComponent("BuildYourCareer")!
  const _component_FAQ = _resolveComponent("FAQ")!
  const _component_OurBlogs = _resolveComponent("OurBlogs")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainNavbar),
    _createVNode(_component_MainBanner),
    _createVNode(_component_BrandsContent),
    _createVNode(_component_AboutUs),
    _createVNode(_component_CoreFeatures),
    _createVNode(_component_PopularTopics),
    _createVNode(_component_PopularCourses),
    _createVNode(_component_TeamMember),
    _createVNode(_component_SubscribeNewsletter),
    _createVNode(_component_OurTestimonial),
    _createVNode(_component_UpcomingEvents),
    _createVNode(_component_BuildYourCareer),
    _createVNode(_component_FAQ),
    _createVNode(_component_OurBlogs),
    _createVNode(_component_MainFooter)
  ], 64))
}