<template>
  <div class="edu-counter-area02 ptb-100">
    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title">Some Fun Fact</p>
        <h2>Our Great <span class="shape02">Achievement</span></h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="counter-box02">
            <img src="../../assets/img/icon/counter-1.svg" alt="icon" />
            <h3>Creative Galore</h3>
            <p>Our platform features over 100 unique templates designed by award-winning graphic designers from around the world, ensuring your portfolio stands out with cutting-edge style and creativity.</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="counter-box02">
            <img src="../../assets/img/icon/counter-2.svg" alt="icon" />
            <h3>Quick Start</h3>
            <p>Users can create a stunning portfolio in as little as 10 minutes! With our intuitive drag-and-drop interface and pre-made templates, showcasing your work has never been faster.</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="counter-box02">
            <img src="../../assets/img/icon/counter-3.svg" alt="icon" />
            <h3>Global Reach</h3>
            <p>Portfolios created on our platform have been viewed in over 50 countries worldwide! From New York to Tokyo, your work can reach a truly global audience.
</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="counter-box02">
            <img src="../../assets/img/icon/counter-4.svg" alt="icon" />
            <h3>Eco-Friendly Tech</h3>
            <p>Our servers are powered by 100% renewable energy. By choosing our platform, you're not only creating a beautiful portfolio but also contributing to a greener planet.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FunFacts",
};
</script>