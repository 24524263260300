<template>
  <div class="edu-about-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12">
          <div class="edu-about-image">
             <img src="../../assets/img/all-img/about1.png" alt="banner-img" />
          </div>
        </div>
        <div class="col-lg-5 col-md-12">
          <div class="edu-about-content">
            <p class="sub-title">About Showyourskills</p>
            <h2>
              The Place Where You Can <span class="shape02">Achieve</span>
            </h2>
          
            <div class="about-list">
              <div class="items">
                <div class="about-single-content">
                  <div class="icon2">
                   <img src="../../assets/img/svg/light.svg" alt="icon" />
                  </div>
                  <div class="content">
                    <h3>Empowering Creatives Worldwide</h3>
                    <p>
                     Our vision is to empower creatives from all walks of life to showcase their talents and achievements through stunning, professional portfolios. We strive to provide the tools and support necessary for individuals to present their work with confidence and reach a global audience, breaking down barriers to opportunity and recognition.
                    </p>
                  </div>
                </div>
              </div>
              <div class="items">
                <div class="about-single-content">
                  <div class="icon2">
                    <img src="../../assets/img/svg/target.svg" alt="icon" />
                  </div>
                  <div class="content">
                    <h3>Innovating the Digital Portfolio Landscape</h3>
                    <p>
                       We envision becoming the leading platform for digital portfolios by continuously innovating and enhancing our services. By leveraging cutting-edge technology and user-centered design, we aim to set new standards in portfolio creation, ensuring our users always have access to the most advanced and effective tools to display their work in the best possible light.

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>