<template>
  <div class="edu-courses-area pt-70 pb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="blog-details-desc">
            <div class="article-image">
              <img src="../../assets/img/all-img/b-s-1.png" alt="image" />
            </div>
            <div class="article-content">
              <div class="entry-meta">
                <ul>
                  <li><i class="bx bx-user"></i> <a href="#">Admin</a></li>
                  <li><i class="bx bx-calendar"></i>21 Mar, 24</li>
                  <li><i class="bx bx-time-five"></i> 7 Min Read</li>
                  <li>
                    <i class="bx bx-message-rounded"></i
                    ><a href="#">4 Comments</a>
                  </li>
                </ul>
              </div>

              <h2>
                Crafting My Development Portfolio: A Journey of Growth
              </h2>
              <p>
                The first step in my portfolio-making journey was to select a platform that aligns with my needs. After researching various options, I settled on Showyourskills.fi for its intuitive interface and customizable templates. Setting up my account was seamless, and I appreciated the variety of templates tailored specifically for developers like me.
              </p>

              <blockquote class="wp-block-quote">
                <i class="bx bxs-quote-right bx-flip-horizontal"></i>
                <p>
                  “ Personalizing my portfolio was a breeze with Portfolio Maker's customization options. From choosing color schemes that complemented my personal brand to tweaking layouts to enhance readability, every detail contributed to creating a cohesive and professional online presence. It was empowering to have full control over how my work was presented..”
                </p>
                <span>Rosalina D. Jackson</span>
              </blockquote>

              <p>
                One of the highlights of using Portfolio Maker was the ability to showcase my projects effectively. The drag-and-drop editor allowed me to arrange my projects chronologically, highlight key technologies used, and provide detailed descriptions of each project's objectives and outcomes. This feature not only impressed potential employers but also helped me reflect on my growth as a developer over time.
              </p>

              <ul class="wp-block-gallery columns-2">
                <li class="blocks-gallery-item">
                  <figure>
                    <img src="../../assets/img/all-img/b-s-2.png" alt="image" />
                  </figure>
                </li>

                <li class="blocks-gallery-item">
                  <figure>
                    <img src="../../assets/img/all-img/b-s-3.png" alt="image" />
                  </figure>
                </li>
              </ul>

              <h3>
               Final Thoughts: A Tool for Continuous Growth
              </h3>
              <p>
               Creating my development portfolio on Portfolio Maker has been instrumental in advancing my career goals. It's not just a showcase of my technical skills but a reflection of my dedication to continuous learning and improvement. I've learned that a well-crafted portfolio isn't just about displaying projects; it's about telling a compelling story of my journey as a developer and showcasing my passion for innovation.

Whether you're a seasoned developer looking to refresh your portfolio or a newcomer preparing to enter the industry, investing time and effort in creating a professional portfolio is worth every moment. It's more than just a collection of projects; it's your digital identity in the competitive world of tech.

I encourage every developer out there to embark on this journey with the right tools and mindset. Your portfolio is not just a static document; it's a living testament to your skills, achievements, and aspirations in the dynamic field of software development.
              </p>

           

              <div class="article-footer">
                <div class="article-tags">
                  <span>Tags:</span>
                  <a href="#">Business</a>
                  <a href="#">Education</a>
                  <a href="#">Design</a>
                </div>

                <div class="article-share">
                  <span>Share On:</span>
                  <ul class="social">
                    <li>
                      <a href="#" class="facebook" target="_blank"
                        ><img src="../../assets/img/icon/fb.svg" alt="icon"
                      /></a>
                    </li>
                    <li>
                      <a href="#" class="twitter" target="_blank"
                        ><img src="../../assets/img/icon/ins.svg" alt="icon"
                      /></a>
                    </li>
                    <li>
                      <a href="#" class="linkedin" target="_blank"
                        ><img src="../../assets/img/icon/tw.svg" alt="icon"
                      /></a>
                    </li>
                    <li>
                      <a href="#" class="linkedin" target="_blank"
                        ><img src="../../assets/img/icon/pn.svg" alt="icon"
                      /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="edu-post-navigation">
                <div class="prev-link-wrapper">
                  <div class="info-prev-link-wrapper">
                    <a href="blog-single.html">
                      <span class="image-prev">
                        <img
                          src="../../assets/img/all-img/rc-1.png"
                          alt="image"
                        />
                      </span>
                      <span class="prev-link-info-wrapper">
                        <span class="post-nav-title">Prev Post</span>
                        <span class="prev-title"
                          >How Technology Can Help You Stay Healthy</span
                        >
                      </span>
                    </a>
                  </div>
                </div>

                <div class="next-link-wrapper">
                  <div class="info-next-link-wrapper">
                    <a href="blog-single.html">
                      <span class="prev-link-info-wrapper">
                        <span class="post-nav-title">Next Post</span>
                        <span class="prev-title"
                          >How Technology Can Help You Stay Healthy</span
                        >
                      </span>
                      <span class="image-prev">
                        <img
                          src="../../assets/img/all-img/rc-2.png"
                          alt="image"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div> -->
            </div>

            <div class="comments-area">
              <h3 class="comments-title">2 Comments:</h3>

              <ol class="comment-list">
                <li class="comment">
                  <article class="comment-body">
                    <footer class="comment-meta">
                      <div class="comment-author vcard">
                        <img
                          src="../../assets/img/all-img/cmnt-1.png"
                          class="avatar"
                          alt="image"
                        />
                        <h4 class="fn">Ferira Watson</h4>

                        <div class="reply">
                          <a href="#" class="comment-reply-link"
                            ><i class="bx bx-share bx-flip-vertical"></i>
                            Reply</a
                          >
                        </div>
                      </div>
                      <div class="comment-metadata">
                        <span>Oct 09, 2021</span>
                      </div>
                    </footer>
                    <div class="comment-content">
                      <p>
                        I stumbled upon your blog while searching for tips on creating a developer portfolio, and I must say, your journey is inspiring! I've been procrastinating on updating mine, but your experience with Portfolio Maker has convinced me to finally get started. Thanks for sharing!
                      </p>
                      <div class="reply">
                        <a href="#" class="comment-reply-link"
                          ><i class="bx bx-share bx-flip-vertical"></i> Reply</a
                        >
                      </div>
                    </div>
                  </article>

                  <ol class="children">
                    <li class="comment">
                      <article class="comment-body">
                        <footer class="comment-meta">
                          <div class="comment-author vcard">
                            <img
                              src="../../assets/img/all-img/cmnt-2.png"
                              class="avatar"
                              alt="image"
                            />
                            <h4 class="fn">Steven Smith</h4>
                            <div class="reply">
                              <a href="#" class="comment-reply-link"
                                ><i class="bx bx-share bx-flip-vertical"></i>
                                Reply</a
                              >
                            </div>
                          </div>

                          <div class="comment-metadata">
                            <span>Oct 09, 2021</span>
                          </div>
                        </footer>

                        <div class="comment-content">
                          <p>
                            Thank you so much for your kind words! I'm thrilled to hear that my experience has motivated you to take action. Getting started is often the hardest part, but once you dive in, you'll find it's a rewarding process. Feel free to reach out if you have any questions or need tips along the way!
                          </p>
                          <div class="reply">
                            <a href="#" class="comment-reply-link"
                              ><i class="bx bx-share bx-flip-vertical"></i>
                              Reply</a
                            >
                          </div>
                        </div>
                      </article>

                      <ol class="children">
                        <li class="comment">
                          <article class="comment-body">
                            <footer class="comment-meta">
                              <div class="comment-author vcard">
                                <!-- <img
                                  src="../../assets/img/all-img/cmnt-1.png"
                                  class="avatar"
                                  alt="image"
                                /> -->
                                <!-- <h4 class="fn">Sarah Taylor</h4>
                                <div class="reply">
                                  <a href="#" class="comment-reply-link"
                                    ><i
                                      class="bx bx-share bx-flip-vertical"
                                    ></i>
                                    Reply</a
                                  >
                                </div> -->
                              </div>

                              <!-- <div class="comment-metadata">
                                <span>Oct 09, 2021</span>
                              </div> -->
                            </footer>

                            <!-- <div class="comment-content">
                              <p>
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered
                                alteration in some form
                              </p>
                              <div class="reply">
                                <a href="#" class="comment-reply-link"
                                  ><i class="bx bx-share bx-flip-vertical"></i>
                                  Reply</a
                                >
                              </div>
                            </div> -->
                          </article>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
              <div class="comment-respond">
                <h3 class="comment-reply-title">Leave a Reply</h3>

                <form class="comment-form">
                  <p class="comment-notes">
                    <span id="email-notes"
                      >Your email address will not be published.</span
                    >
                    Required fields are marked <span class="required">*</span>
                  </p>

                  <p class="comment-form-author">
                    <label>Name <span class="required">*</span></label>
                    <input
                      type="text"
                      id="author"
                      placeholder="Your Name*"
                      name="author"
                    />
                  </p>

                  <p class="comment-form-email">
                    <label>Email <span class="required">*</span></label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Your Email*"
                      name="email"
                    />
                  </p>

                  <!-- <p class="comment-form-url">
                    <label>Website</label>
                    <input
                      type="url"
                      id="url"
                      placeholder="Website"
                      name="url"
                    />
                  </p> -->

                  <p class="comment-form-comment">
                    <label>Comment</label>
                    <textarea
                      name="comment"
                      id="comment"
                      cols="45"
                      placeholder="Your Comment..."
                      rows="5"
                      maxlength="65525"
                    ></textarea>
                  </p>

                  <p class="comment-form-cookies-consent">
                    <input
                      type="checkbox"
                      value="yes"
                      name="wp-comment-cookies-consent"
                      id="wp-comment-cookies-consent"
                    />
                    <label for="wp-comment-cookies-consent">
                      Save my name, email, and website in this browser for the
                      next time I comment.
                    </label>
                  </p>

                  <p class="form-submit">
                    <input
                      type="submit"
                      name="submit"
                      id="submit"
                      class="submit"
                      value="Post Comment"
                    />
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <SideBar />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SideBar from "./SideBar.vue";

export default defineComponent({
  name: "BlogDetails",
  components: {
    SideBar,
  },
});
</script>